<div class="card center">
  <form (submit)="saveUpload()" enctype="multipart/form-data">
    <div class="card-body " [ngClass]="{'hiddenCropper':!croppedImage}">
      <div class="img-container" *ngIf="!imageChangedEvent">
        <img class="image" src="{{logoPath}}" />
        <div class="middle">
          <label class="action-button" for="file-input">
            <i class="fa fa-edit"></i>
          </label>
        </div>
      </div>
      <image-cropper *ngIf="imageChangedEvent" class="profile-logo" [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [resizeToWidth]="512" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
      <div class="file-chooser">
        <input type="file" #uploaderInput id="file-input" (change)="fileChangeEvent($event)" accept="image/x-png,image/gif,image/jpeg" />
      </div>
    </div>
    <div class='card-footer' *ngIf="imageChangedEvent">
      <button class="btn btn-primary upload-buttons" type="submit">Save</button>
      <button class="btn btn-danger upload-buttons" (click)="cancelUpload()">Cancel</button>
    </div>
  </form>
</div>
