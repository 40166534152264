import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { Routes, RouterModule } from '@angular/router';
import { ChangeLogoComponent } from './change-logo/change-logo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [
  { path: '', component: SettingsComponent },
  { path: 'new', component: SettingsComponent },
  { path: 'edit/:id', component: SettingsComponent }
];

@NgModule({
  imports: [
    CommonModule,
    ImageCropperComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    RouterModule.forChild(routes),
  ],
  declarations: [SettingsComponent, ChangeLogoComponent]
})

export class SettingsModule { }
