<div class='wrapper page container'>
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left">{{'settings.title' | translate}}</h3>
      <button type="button" (click)="onSubmit()" class="btn blue-bg mg-12 float-right header-button">{{'settings.save'
        | translate}}</button>
    </div>
  </div>
  <div class="row content-center">
    <div class="col-md-12 col-xs-12">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <app-change-logo *ngIf="profile != 'SUPERADMIN' && profile != 'RESELLER'"></app-change-logo>
          <div *ngIf="profile == 'SUPERADMIN' || profile == 'RESELLER'"  class="card center">
                <div class="card-body ">
                  <div class="img-container" >
                    <img class="image1" src="{{logoPath}}" />
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body ">
          <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6">
                <div class="has-float-label">
                  <input class="custom-float-label-input" type="text" placeholder=" " formControlName="companyName" id="companyName">
                  <label class="label custom-label" for="companyName"> {{'settings.name' | translate}} </label>
                </div>
                <div class="has-float-label">
                  <input class="custom-float-label-input" type="text" placeholder=" " formControlName="vatNumber" id="vatNumber">
                  <label class="label custom-label" for="vatNumber"> {{'settings.iva' | translate}} </label>
                </div>
                <div class="has-float-label">
                  <input class="custom-float-label-input" type="text" placeholder=" " formControlName="fiscalCode" id="fiscalCode">
                  <label class="label custom-label" for="fiscalCode"> {{'settings.fiscal-code' | translate}}
                  </label>
                </div>
                <div class="has-float-label">
                  <input class="custom-float-label-input" type="text" placeholder=" " formControlName="pecAddress" id="pecAddress">
                  <label class="label custom-label" for="pecAddress"> {{'settings.address' | translate}} </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="has-float-label">
                  <ng-select class="custom" placeholder="{{ 'settings.day-of-payment' | translate }}" formControlName="paymentDay">
                    <ng-option *ngFor="let day of days" [value]="day.number">{{day.number}}</ng-option>
                  </ng-select>
                </div>
                <div class="has-float-label">
                  <input class="custom-float-label-input" type="text" placeholder=" " formControlName="mobileNumber" id="mobileNumber">
                  <label class="label custom-label" for="mobileNumber"> {{'settings.tel' | translate}} </label>
                </div>
                <div class="has-float-label">
                  <input class="custom-float-label-input" type="text" placeholder=" " formControlName="phoneNumber" id="phoneNumber">
                  <label class="label custom-label" for="phoneNumber"> {{'settings.phone' | translate}} </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
