import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer } from '@angular/forms';
import { Location } from '@angular/common';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { SettingService } from '../../../services/setting.service';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SettingsComponent implements OnInit {
  settingsDetails: any = [];
  profile:any;
  profileForm = this.fb.group({
    companyName: [''],
    vatNumber: [''],
    fiscalCode: [''],
    phoneNumber: [''],
    mobileNumber: [''],
    paymentDay: [null],
    pecAddress: ['', Validators.required],
  });
  logoPath:any
  msgTranslation;
  errorTranslation;
  errorMessage;
  originalSettingsDetail: {};

  days = [
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
    { number: 6 },
    { number: 7 },
    { number: 8 },
    { number: 9 },
    { number: 10 },
    { number: 11 },
    { number: 12 },
    { number: 13 },
    { number: 14 },
    { number: 15 },
    { number: 16 },
    { number: 17 },
    { number: 18 },
    { number: 19 },
    { number: 20 },
    { number: 21 },
    { number: 22 },
    { number: 23 },
    { number: 24 },
    { number: 25 },
    { number: 26 },
    { number: 27 },
    { number: 28 }
  ]

  constructor(
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private location: Location,
    public colors: ColorsService,
    public http: HttpClient,
    public settings: SettingsService,
    private settingService: SettingService) {
      this.translate.get('error').subscribe((res : string) => { this.errorTranslation = res; });
      this.translate.get('msg').subscribe((res : string) => { this.msgTranslation = res; });
      this.profile = localStorage.getItem("profile");
  }

  ngOnInit() {
    this.settingService.getSettingsDetails().subscribe((settingsDetails: any) => {
      this.profileForm.setValue(settingsDetails)
      this.originalSettingsDetail = settingsDetails;
    })
    this.logoPath = localStorage.getItem('logo')

    if(this.logoPath == null) {
      this.logoPath = 'assets/img/logo-single.png';
    } else {
      if(this.profile == 'SUPERADMIN' || this.profile == 'RESELLER') {
        this.logoPath = 'assets/img/logo-single.png';
      }
    }
    var disabledInputs = ['companyName', 'vatNumber', 'fiscalCode'];
    this.disableInputs(disabledInputs)
  }

  disableInputs(inputs) {
    for (var i = 0; i < inputs.length; i++) {
      this.profileForm.get(inputs[i]).disable()
    }
  }

  onSubmit() {
    for (var i in this.profileForm.controls) {
      this.profileForm.controls[i].markAsTouched();
    }
    if (this.profileForm.status == 'VALID') {
      this.save(this.profileForm.value)
    }
  }

  save(profileDetails) {
    profileDetails['companyName'] = this.originalSettingsDetail['companyName']
    profileDetails['vatNumber'] = this.originalSettingsDetail['vatNumber']
    profileDetails['fiscalCode'] = this.originalSettingsDetail['fiscalCode']
    this.settingService.updateSettingsDetail(profileDetails).subscribe((response: any) => {
      if (response.outcome.success === true) {
        swal.fire("Success", "", "success");
      } else {
        swal.fire("Error", this.settings.manageErrorMsg(response.outcome), "error");
      }
    },
    (error) => {
      this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
      swal.fire("Error", this.errorMessage, "error");
    })
  }
}
