/* src/app/routes/settings/settings/settings.component.scss */
.content-center {
  justify-content: center;
}
.title-card {
  padding: 15px;
  font-weight: 400;
  font-size: 17px;
}
.save-button {
  text-align: right;
  line-height: 70px;
}
.center {
  text-align: center;
}
.profile-logo {
  height: 200px;
  width: 200px;
}
.image1 {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
/*# sourceMappingURL=settings.component.css.map */
